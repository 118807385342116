<template>
  <div class="content">
    <sub-title>스포츠결과</sub-title>
    <div style="width: 100%; margin-top:.1rem">
      <sports-result-comp :game="item" v-for="(item,idx) in gameList" :key="idx"></sports-result-comp>
    </div>
    <pagination :page-index="pageNum"
                :total="total"
                :page-size="pageSize"
                @change="pageChange"></pagination>
  </div>
</template>

<script>

import Left from "@/views/afront/Left.vue";
import Header from "@/views/afront/Header.vue";
import Right from "@/views/afront/Right.vue";
import Footer from "@/views/afront/Footer.vue";
import SubTitle from "@/views/afront/zero/SubTitle.vue";
import SportsGameTypeSelector from "@/views/afront/sports/gametype/SportsGameTypeSelector.vue";
import sportsConst from "@/common/sportsConst";
import {postionMixin, sportsGameCountMixin, sportsMatchMixin} from "@/common/mixin";
import SportsScrollToTop from "@/views/afront/zero/ScrollToTop.vue";
import SportsResultComp from "@/views/afront/sports/match/SportsResultComp.vue";
import {RECEIVE_DISPLAY_POSTION, RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";
import {getSportsGameEnd} from "@/network/sportsRequest";
import {fix2, kindText} from "@/common/SportsUtils";
import Pagination from "@/components/pagenation/Pagination.vue";

export default {
  name: "SportsResult",
  mixins:[postionMixin,sportsMatchMixin,sportsGameCountMixin],
  components: {
    Pagination,
    SportsResultComp,
    SportsScrollToTop,  SportsGameTypeSelector, SubTitle, Footer, Right, Header, Left},
  data() {
    return {
      displayPosition: sportsConst.GAME_DISPLAYPOSITION_1x2,
      kind:sportsConst.GAME_KIND_1X2,
      pageNum: 1,
      pageSize: 20,
      total: 1,
      orderBy: 'g.start_time DESC',
      search: {},
      gameList: [],
      position:"경기결과(S)",
      sportsConst,
    }
  },
  methods: {
    getGames() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      this.search.displayPosition = this.displayPosition
      this.search.kind = this.kind
      getSportsGameEnd(this.search, this.pageNum, this.pageSize, this.orderBy).then(res => {

        if (res.data.success) {
          this.total = res.data.total
          this.gameList = res.data.data
          this.gameList.forEach(g => {
            //배당 소수점 두자리 설정 1.8 => 1.80
            try{
              fix2(g)
              //승무패,핸디오언 텍스트 설정
              kindText(g)
            }catch (e) {

            }
          })
        }
        this.$store.commit(RECEIVE_HIDE_LOADING)
      })
    },
    changeSportsDisplay(disp,kind) {
      this.$store.commit(RECEIVE_DISPLAY_POSTION, 0);
      this.$store.commit(RECEIVE_DISPLAY_POSTION, disp);
      this.kind = kind
    },
    pageChange(page) {
      this.pageNum = page
      this.getGames()
    }
  },
  created() {
    this.getGames();
  },
}
</script>

<style scoped>
@import url("../../../../assets/css/front/afrontsports.css");
</style>